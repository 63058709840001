<template>
  <div class="card shadow mb-7">
    <div class="card-body pt-6 pb-0">
      <!--begin::Navs-->
      <div class="d-flex justify-content-between overflow-auto h-55px">
        <ul
          class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
        >
          <li class="nav-item">
            <router-link
              to="/omologazioni/richieste"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Richieste omologazione
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/omologazioni/prese-in-carico"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Prese in carico
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/omologazioni/in-attesa"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              In attesa di verifica
            </router-link>
          </li>
          <!--  <li class="nav-item">
            <router-link
              to="/omologazioni/esito"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Esito
            </router-link>
          </li> -->
          <li class="nav-item">
            <router-link
              to="/omologazioni/approvate"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Approvate
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/omologazioni/rifiutate"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Rifiutate
            </router-link>
          </li>
        </ul>
      </div>
      <!--begin::Navs-->
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import isEnabled from "@/composables/isEnabled.js";

export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Omologazioni");
    });

    return { isEnabled };
  },
});
</script>
